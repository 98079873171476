const Cart = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.91009 3.50334C2.05355 3.19625 2.36184 3 2.70079 3H4.11631C4.51647 3 4.86535 3.27214 4.96278 3.66025L5.4037 5.41678H18.1654C19.304 5.41678 20.1327 6.48749 19.8567 7.58719L18.4384 13.2374C18.1461 14.4018 17.0994 15.2182 15.899 15.2182H8.1066C6.90728 15.2182 5.86021 14.4047 5.56767 13.2393L3.92522 6.69608L2.03168 4.43304C1.81417 4.17309 1.76663 3.81044 1.91009 3.50334ZM5.84183 7.16224L7.2606 12.8143C7.35774 13.2013 7.7053 13.4727 8.1066 13.4727H15.899C16.2991 13.4727 16.648 13.2006 16.7454 12.8125L18.1637 7.16224L5.84183 7.16224ZM7.79022 19.0014C8.59354 19.0014 9.24477 18.3501 9.24477 17.5468C9.24477 16.7435 8.59354 16.0923 7.79022 16.0923C6.9869 16.0923 6.33567 16.7435 6.33567 17.5468C6.33567 18.3501 6.9869 19.0014 7.79022 19.0014ZM17.973 17.5468C17.973 18.3501 17.3217 19.0014 16.5184 19.0014C15.7151 19.0014 15.0639 18.3501 15.0639 17.5468C15.0639 16.7435 15.7151 16.0923 16.5184 16.0923C17.3217 16.0923 17.973 16.7435 17.973 17.5468Z"
      />
    </svg>
  )
}

export default Cart
